import { useMutation } from "@tanstack/react-query";

import { redirectToCustomerPortal } from "../apiTransaction";

const useRedirectToCustomerPortal = () => {
  return useMutation({
    mutationFn: redirectToCustomerPortal,
    onSuccess: (response) => {
      window.location.href = response.url;
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export default useRedirectToCustomerPortal;
