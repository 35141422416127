// https://github.com/cloudinary-community/cloudinary-examples/blob/main/examples/react-upload-widget-preset/src/components/UploadWidget/UploadWidget.js
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import { useEffect } from "react";

let cloudinary;
let widget;

const UploadWidget = ({ onUpload, disabled = false }) => {
  useEffect(() => {
    // Store the Cloudinary window instance to a ref when the page renders

    // if (!cloudinary) {
    //   cloudinary = window.cloudinary;
    //   console.log("cloudinary", cloudinary);
    // }

    // function onIdle() {
    //   if (!widget) {
    //     widget = createWidget();
    //   }
    // }

    cloudinary = window.cloudinary;
    function onIdle() {
      widget = createWidget();
    }

    "requestIdleCallback" in window
      ? requestIdleCallback(onIdle)
      : setTimeout(onIdle, 1);

    // eslint-disable-next-line
  }, []);

  /**
   * createWidget
   * @description Creates a new instance of the Cloudinary widget and stores in a ref
   */

  function createWidget() {
    const options = {
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
      uploadPreset: "glitch-test",
      multiple: false,
      maxFileSize: 25000000, //less than 25MB
      clientAllowedFormats: [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "mp4",
        "mov",
        "wmv",
        "avi",
        "mkv",
      ],
      sources: [
        "local",
        "url",
        "camera",
        "image_search",
        "google_drive",
        "dropbox",
        "shutterstock",
        "getty",
        "istock",
        "unsplash",
      ],
    };

    return cloudinary?.createUploadWidget(options, function (error, result) {
      // The callback is a bit more chatty than failed or success so
      // only trigger when one of those are the case. You can additionally
      // create a separate handler such as onEvent and trigger it on
      // ever occurance
      if (error || result.event === "success") {
        onUpload(error, result, widget);
      }
    });
  }

  /**
   * open
   * @description When triggered, uses the current widget instance to open the upload modal
   */

  function open() {
    if (!widget) {
      widget = createWidget();
    }
    widget && widget.open();
  }

  function handleOnClick(e) {
    e.preventDefault();
    open();
  }

  return (
    <Button
      variant="outlined"
      color="inherit"
      onClick={handleOnClick}
      disabled={disabled}
      sx={{
        padding: 3,
        alignItems: "center",
        justifyContent: "center",
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
      }}
    >
      Image/Video Upload
    </Button>
  );
};

export default UploadWidget;
