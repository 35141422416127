import { useQuery } from "@tanstack/react-query";

import { getPrivateReplies } from "../apiPage";

const useGetPrivateReplies = ({ pageId }: { pageId: string }) => {
  const {
    isLoading: privateRepliesIsLoading,
    data: { data } = {},
    error,
  } = useQuery({
    queryKey: ["page", pageId, "privateReplies"],
    queryFn: () => getPrivateReplies(pageId),
    retry: false,
  });

  const privateRepliesData = data?.data;

  return { privateRepliesIsLoading, privateRepliesData, error };
};

export default useGetPrivateReplies;
