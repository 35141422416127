import apiClient from "./client";

const SHOPIFY_APP_ID = process.env.REACT_APP_SHOPIFY_APP_HANDLE;

const getTransaction = async ({ pageId }: { pageId: string }) => {
  const response = await fetch(`/api/v2/transaction/${pageId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { status, transaction } = await response.json();
  if (status === "fail") {
    throw new Error("Failed to fetch transaction");
  }

  return transaction;
};

const createCheckoutSession = async ({
  pageId,
  planId,
}: {
  pageId: string;
  planId: string;
}) => {
  const response = await fetch(`/api/v2/transaction/checkout/${pageId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ planId }),
  });
  const { session } = await response.json();

  // Redirect to the session URL
  window.location.href = session.url;
};

const redirectToCustomerPortal = async (pageId: string) => {
  const response = await apiClient.post(`/api/v2/transaction/${pageId}/portal`);
  return response.data;
};

const getShopifyManagedBillingUrl = () => {
  const shopifyBillingUrl = `https://admin.shopify.com/charges/${SHOPIFY_APP_ID}/pricing_plans`;

  window.location.href = shopifyBillingUrl;
};

export {
  createCheckoutSession,
  redirectToCustomerPortal,
  getTransaction,
  getShopifyManagedBillingUrl,
};
