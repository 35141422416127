import { yupResolver } from "@hookform/resolvers/yup";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import useUpdatePrivateReply from "../../apis/hooks/use-update-private-reply";
import FormProvider, {
  RHFSwitch,
  RHFTextField,
} from "../../components/hook-form";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

type Props = {
  row: {
    pageId: string;
    privateReplyId: string;
    messageTemplateId: string;
    keyword: string;
    isLive: boolean;
  };
};

export default function PrivateReplyRow({ row }: Props) {
  const theme = useTheme();
  const [editPrivateReplyOptIn, setEditPrivateReplyOptIn] = useState(false);

  const updatePagePrivateReply = useUpdatePrivateReply();

  const PrivateReplySchema = Yup.object().shape({
    keyword: Yup.string().required("Keyword is required"),
    isLive: Yup.boolean().required(),
  });

  const defaultValues = {
    keyword: row.keyword,
    isLive: row.isLive,
  };

  const methods = useForm({
    resolver: yupResolver(PrivateReplySchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset({
      keyword: row.keyword,
      isLive: row.isLive,
    });
  }, [row, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const formData = { ...row, ...data };

    updatePagePrivateReply.mutate({ pageId: row.pageId, formData });
    setEditPrivateReplyOptIn(false);
  });

  const handleCancel = () => {
    reset();
    setEditPrivateReplyOptIn(false);
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack
          spacing={3}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent={{ xs: "flex-start", sm: "space-between" }}
        >
          <Stack direction={{ xs: "row" }}>
            <RHFSwitch
              name="isLive"
              label={null}
              disabled={!editPrivateReplyOptIn}
              sx={{ m: 0 }}
            />
            <RHFTextField
              name="keyword"
              size="small"
              label="Keyword"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              disabled={!editPrivateReplyOptIn}
              sx={(theme) => ({
                width: 300,
                borderRadius: 1,
                backgroundColor: editPrivateReplyOptIn
                  ? theme.palette.background.paper
                  : theme.palette.grey[300],
              })}
            />
          </Stack>

          {editPrivateReplyOptIn ? (
            <Stack direction={{ xs: "row" }} spacing={2}>
              <Button
                size="small"
                type="submit"
                startIcon={<Iconify icon="ion:save" />}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: theme.palette.grey[500],
                  minWidth: "125px",
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                startIcon={<Iconify icon="typcn:cancel" />}
                onClick={handleCancel}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: theme.palette.grey[500],
                  minWidth: "125px",
                }}
              >
                Cancel
              </Button>
            </Stack>
          ) : (
            <Button
              size="small"
              startIcon={<Iconify icon="typcn:edit" />}
              onClick={() => setEditPrivateReplyOptIn(true)}
              sx={{
                p: 2,
                border: 1,
                borderColor: theme.palette.grey[500],
                minWidth: "125px",
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
      </FormProvider>
    </>
  );
}
