import apiClient from "./client";

const getConnectedPages = async ({ userId }: { userId: string }) => {
  const response = await fetch(`/api/page/user/${userId}`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getPage = async (pageId: string) => {
  return apiClient.get(`/api/page/${pageId}`);
};

const updateReplyText = async ({
  pageId,
  replyText,
}: {
  pageId: string;
  replyText: string;
}) => {
  const response = await fetch(`/api/page/${pageId}/reply-text`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ replyText }),
  });
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getApiKeys = async ({ pageId }: { pageId: string }) => {
  const response = await fetch(`/api/v2/page/${pageId}/apiKeys`);
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const createShopifyAPIKey = async ({
  pageId,
  shopifyUrl,
}: {
  pageId: string;
  shopifyUrl: string;
}) => {
  const response = await fetch(
    `/api/v2/page/${pageId}/createShopifyIntegration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ shopifyUrl }),
    }
  );
  const data = await response.json();
  if (data.status === "fail") {
    throw new Error(data.message);
  }
  return data;
};

const getPageByShopifyUrl = async ({ shopifyUrl }: { shopifyUrl: string }) => {
  const response = await fetch(`/api/page/shopify/${shopifyUrl}`);
  const data = await response.json();

  return data;
};

const getPagePricingPlanCompliance = async (pageId: string) => {
  return apiClient.get(`/api/v2/page/${pageId}/pricingPlanCompliance`);
};

const updatePageAttributionWindow = async ({
  pageId,
  data,
}: {
  pageId: string;
  data: any;
}) => {
  const response = await apiClient.put(
    `/api/v2/page/${pageId}/attributionWindow`,
    data
  );
  return response.data;
};

const getPrivateReplies = async (pageId: string) => {
  return apiClient.get(`/api/v2/page/${pageId}/privateReply`);
};

const updatePrivateReply = async ({
  pageId,
  formData,
}: {
  pageId: string;
  formData: any;
}) => {
  const response = await apiClient.put(
    `/api/v2/page/${pageId}/privateReply`,
    formData
  );
  return response.data;
};

export {
  getConnectedPages,
  getPage,
  updateReplyText,
  getApiKeys,
  createShopifyAPIKey,
  getPageByShopifyUrl,
  getPagePricingPlanCompliance,
  updatePageAttributionWindow,
  getPrivateReplies,
  updatePrivateReply,
};
